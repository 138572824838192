import axios from 'src/utils/axios';
import { useCallback, useState, useEffect} from "react"
import useIsMountedRef from "../hooks/useIsMountedRef"

// ----------------------------------------------------------------------

function UserDetails() {
  const isMountedRef = useIsMountedRef();
  const [user, setUser] = useState({});

  const getUser = useCallback(async () => {
    try {
      const response = await axios.get('/users/current', {});
      if (isMountedRef.current) {
        setUser(response.data);
      }
    } catch (err) {
      //
    }
  }, [isMountedRef]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  return <div>{user.email}</div>;
}

export function UserFilters() {
  const isMountedRef = useIsMountedRef();
  const [filters, setFilters] = useState([]);

  const getFilters = useCallback(async () => {
    try {
      const response = await axios.get('/users/filters/list', {});
      if (isMountedRef.current) {
        setFilters(response.data);
      }
    } catch (err) {
      //
    }
  }, [isMountedRef]);

  useEffect(() => {
    getFilters();
  }, [getFilters]);

  return filters;
}


export async function get_user(){
  const response =  await axios.get('/users/current', {});
  return await response.data
}

export async function get_invoice(price_id){
  const response =  await axios.get(`/users/payments/invoice/${price_id}`, {});
  return await response.data
}

export async function get_portal(){
  const response =  await axios.get(`/users/payments/portal`, {});
  return await response.data
}

export async function update_user(user = {}){
  const response =  await axios.put('/users/current/update', {...user});
  return await response.data
}

export async function get_filters(){
  const response =  await axios.get('/users/filters/list', {});
  return response.data
}

export function delete_filter(id){
  const response =  axios.delete(`/users/filters/delete/${id}`, {});
  
  return response.data
}

export async function create_filter(name, url){
  const response =  await axios.post('/users/filters/add', {name: name, url: url});
  return response.data
}




